import React from "react";

export const TreeNode = ({ node }) => {
    const { left, right, element } = node;
    return (
        <>
            <div id={element} className="node-element">{element}</div>
            {(left || right) ?
                <>
                    <div className="bottom-line"></div>
                    <div className="node-children">
                        {left ?
                            <div className="node">
                                <TreeNode key={`${element}-left`} node={left} />
                            </div>
                            :
                            <div className="node">
                                <div className="null-node"></div>
                            </div>
                        }
                        {right ?
                            <div className="node">
                                <TreeNode key={`${element}-right`} node={right} />
                            </div>

                            :
                            <div className="node">
                                <div className="null-node"></div>
                            </div>
                        }
                    </div>
                </>
                : ""
            }
        </>
    )
}
import React, { useState } from "react";
import { arr, startMooresAlgorithm } from "./algorithm";

import {BackNavigator} from '../BackNavigator'

import './style.css';

export const MooreVotingAlgorithm = () => {
    const [isDisabled, setDisabled] = useState(false);

    const handleStart = async () => {
        setDisabled(true);
        await startMooresAlgorithm(arr);
    }
    return (
        <div className="moore-algo-container">
            <BackNavigator />
            <h2>Moore's Voting Algorithm</h2>
            <div className="moore-algo-detials">
                <p>Question: Find the majority element in an array (with a frequency exceeding half of the array length).</p>
                <p>Solution: Knowing that the majority element frequency surpasses half the array length, we employ Moore’s Voting Algorithm. This algorithm iterates through the array, updating the majority element candidate as it counts occurrences. If another element's frequency surpasses the majority element, it updates accordingly.
                </p>
            </div>
            <div className="moore-algo-counter-display">
                <div id="moore-algo-counter-bar" className="moore-algo-counter-bar"></div>
                <div id="moore-algo-counter-elem" className="moore-algo-counter-elem"></div>
            </div>
            <div id="moore-algo-array" className="moore-algo-array">
                {arr.map((element, index) => {
                    return (
                        <div
                            key={index}
                            id={`moore-algo-array-element-${index}`}
                            className="moore-algo-array-element"
                            style={{
                                gridColumnStart: index + 1,
                                gridColumnEnd: index + 2,
                                border: "brown solid 1px"
                            }}
                        >
                            {element}
                        </div>
                    )
                })}
            </div>
            <div className="moore-algo-buttons-group">
            <button
                id="moore-algo-start-button"
                className={isDisabled ? "moore-algo-disabled-button" : "moore-algo-start-button"}
                disabled={isDisabled}
                onClick={handleStart}
            >
                Start
            </button>
            <button
                className={!isDisabled ? "moore-algo-disabled-button" : "moore-algo-start-button"}
                onClick={() => window.location.reload()}
            >
                Reset
            </button>
            </div>
            <script src="./algorithm.js"></script>
            <script src="./script.js"></script>
        </div>
    )
}
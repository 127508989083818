import React from "react";
import {BackNavigator} from "../BackNavigator"
import './style.css';
import { sortingAlgos, swapHeights } from "./alogrithm";
import { generateColors } from "./utils";
import { Col, Row } from "react-bootstrap";
const generateColor = generateColors(10);
const sortingAlgo = sortingAlgos();

export const VisualSort = () => {

    const handleClick = ()=>{
        sortingAlgo.forEach(elem => {
            elem.callback(elem.nums, swapHeights, elem.prefix)
        })
    }

    return (
        <div className="visualSort">
            <BackNavigator />
            <div id="mainDiv">
                <Row className="visual-sort-row">
                {
                    sortingAlgo.map(algo=>(
                        <Col lg={3} md={1} id="parentDiv" key={algo.prefix}>
                            <div id="label">
                                <p id="title">{algo.prefix.toUpperCase() + " SORT"}</p>
                                <p id={`${algo.prefix}-timer`}>0</p>
                            </div>
                            {
                                algo.nums.map((n,i)=>(
                                        <div id={algo.prefix+i} key={i} style={{
                                            width: '10%',
                                            border:'solid black 1px',
                                            height:n*2+'px',
                                            backgroundColor:generateColor[i]
                                        }}></div>
                                    )
                                )
                            }
                        </Col>
                        )
                    )
                }
                </Row>
            </div>
            <button id="startBtn" onClick={handleClick}>CLICK ME TO START SORTING</button>
        </div>
    )
}
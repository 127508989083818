import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import linkedinLogo from "../assets/img/nav-icon1.svg";
import leetcodeLogo from '../assets/img/leetcodelogo.svg';
import gfgLogo from '../assets/img/gfglogo.svg';
import cnLogo from '../assets/img/cnlogo.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6} className="footer-name">
            <p>Prince Verma</p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/prince-verma-edignite/" target="_blank"><img src={linkedinLogo} alt="Icon" /></a>
              <a href="https://leetcode.com/u/princevermasoni/" target="_blank"><img src={leetcodeLogo} alt="" /></a>
              <a href="https://www.geeksforgeeks.org/user/princevermasrcc/" target="_blank"><img src={gfgLogo} alt="" /></a>
              <a href="https://www.naukri.com/code360/profile/d0d00a04-0da4-4a86-bb58-ceccc483377c" target="_blank"><img src={cnLogo} alt="" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ProjectCard = ({ title, imgUrl , link, isExternal=false}) => {
  const navigate = useNavigate();

  const goToLink = (link) => {
    if (isExternal) {
      window.open(link, '_blank'); // Open in a new tab
    } else {
      navigate(link);
    }
  }
  
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <button className="proj-view-btn" onClick={() => goToLink(link)}>View</button>
        </div>
      </div>
    </Col>
  )
}

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const skillsList = [
    {
      id: 1,
      title: "Frontend Development",
      skills: ["JavaScript", "HTML", "CSS", "ReactJS", "Angular", "VueJS"]
    },
    {
      id: 2,
      title: "Database",
      skills: ["SQL", "MongoDB"]
    },
    {
      id: 3,
      title: "Backend Development",
      skills: ["Java", "C++", "NodeJS", "ExpressJS", "NextJS"]
    },
    {
      id: 4,
      title: "Miscellaneous",
      skills: ["Data Structures", "Algorithms", "Grunt", "Webpack", "Git", "Agile"]
    },
    {
      id: 5,
      title: "Soft Skills",
      skills: ["Problem Solving", "Leadership", "Team Work"]
    },

  ]

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>I have acquired several skills in frontend as well as backend development<br></br> in my 2 years of developement experience. Here are some of them.</p>
              <Carousel 
              responsive={responsive} 
              infinite={true} 
              removeArrowOnDeviceType="mobile"
              autoPlay
              className="owl-carousel owl-theme skill-slider">
                {skillsList.map((skill) => {
                  return (
                    <div className="item skill-card" key={skill.id}>
                      <h4>{skill.title}</h4>
                      <p>
                      {skill.skills.join(", ")}
                      </p>
                    </div>
                  )
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}

import React from "react";
import { Link } from "react-router-dom";
import arrrow from '../assets/img/arrow1.svg'

export const BackNavigator = () => {
    return(
        <div className="back-navigator">
            <button onClick={() => window.history.back()}>
                <img src={arrrow} />
            </button>
            <span>Back to Portfolio</span>
        </div>
    )
}
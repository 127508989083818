import React, { useState } from "react";
import { listNode1 as head } from "./linkedList";
import { detectCycle } from "./algorithm";

import {BackNavigator} from "../BackNavigator";

import './style.css';

export const LinkedListCycle = () => {
    const [isDisabled, setIsDisabled] = useState(false);

    const startCycle = async () => {
        setIsDisabled(true);
        await detectCycle(head);
    }

    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <div className="linked-list-cycle-container">
            <BackNavigator />
            <h3 style={{textAlign: "center", color:"black"}}>Click on start button to view Floyd's Cycle detection algorithm in action!</h3>
            <div className="linked-list-legend">
                <div className="slow-pointer-indicator"></div>
                <span className="indicator-text">- Color of slow pointer</span>

                <div className="fast-pointer-indicator"></div>
                <span className="indicator-text">- Color of fast pointer</span>
            </div>
            <div id="linked-list">
                {arr.map((i) => {
                    return (
                        <>
                            <div className="linked-list-node" key={i} id={`list-node-${i}`}>
                                {i}
                            </div>
                            <hr className="linked-list-line" />
                        </>
                    )
                })}
                <div className="cycle-connector"></div>
            </div>
            <div className="linked-list-cycle-button-group">
            <button
                id="linkedListCycleStartBtn"
                className={isDisabled ? "disabledbtn" : "btn"}
                disabled={isDisabled}
                onClick={startCycle}>
                Start
            </button>
            <button
                className={isDisabled ? "btn" : "disabledbtn"}
                disabled={!isDisabled}
                onClick={() => {window.location.reload()}}>
                Reset
            </button>
            </div>
        </div>
    )
}
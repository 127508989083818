const sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

export async function generateFibNumbers(n) {
    const fibBarContainer = document.getElementById("fibbars");
    console.log(fibBarContainer.clientHeight, "fdfef");
    const allowableHeight = fibBarContainer.clientHeight - 40;
    const unitSize = allowableHeight / 610;
    console.log(unitSize, allowableHeight, "sdsd");

    let arr = [0, 1]
    let position = 2;

    while (position <= n) {
        let prev1 = arr.length - 2;
        let prev2 = arr.length - 1;

        let prev1Bar = document.getElementById(`bar${prev1}`);
        let prev2Bar = document.getElementById(`bar${prev2}`);

        await sleep(250);

        prev1Bar.style.backgroundColor = "orange";
        prev2Bar.style.backgroundColor = "orange";

        let newNum = arr[prev1] + arr[prev2];
        arr.push(newNum);

        const percentHeight = newNum*unitSize*100/allowableHeight;

        let newBar = document.createElement('div');
        newBar.className = "bar";
        newBar.id = `bar${arr.length - 1}`;
        newBar.style.height = `${percentHeight+1}%`;
        newBar.style.backgroundColor = "pink";

        await sleep(250);

        let mainDiv = document.getElementById("fibbars");
        mainDiv.appendChild(newBar);

        await sleep(500);

        prev1Bar.style.backgroundColor = "lightblue";
        prev2Bar.style.backgroundColor = "lightblue";


        prev1 = prev2;
        prev2 = newNum;

        position++;
    }

    await sleep(250);

    let lastBar = document.getElementById(`bar${arr.length - 1}`);
    lastBar.style.backgroundColor = "lightblue"
}
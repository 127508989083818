import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Home } from './Components/Home';
import { VisualSort } from './Components/VisualSort/VisualSort';
import {
  HashRouter as Router,
  Routes
} from "react-router-dom";
import { Route } from 'react-router-dom';
import { VisualTraversal } from './Components/VisualTraversal/VisualTraversal';
import { Suspense } from 'react';
import { FibonacciNumbers } from './Components/FibonacciNumbers/FibonacciNumbers';
import { TreeTraversal } from './Components/TreeTraversal/TreeTraversal';
import { LinkedListCycle } from './Components/LinkedListTraversal/LinkedListCycle';
import { MooreVotingAlgorithm } from './Components/MooreVotingAlgoritm/MooreVotingAlgorithm';

function App() {
  return (
    <Router basename='/'>
      <div className="App">
      <Suspense fallback={<div>Loading......</div>}>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/visual-sorting-project' element={<VisualSort />} />
            <Route exact path='/visual-traversal-project' element={<VisualTraversal />} />
            <Route exact path='/fibonacci-numbers-project' element={<FibonacciNumbers />} />
            <Route exact path='/tree-traversal-project' element={<TreeTraversal />} />
            <Route exact path='/linked-list-cycle-project' element={<LinkedListCycle />} />
            <Route exact path='/moore-voting-algo-project' element={<MooreVotingAlgorithm />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;

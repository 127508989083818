
function sleep(time){
    return new Promise(resolve => {
        setTimeout(resolve, time)
    })
}

export const getTreeData = () => {
    return {
        element: '0',
        left: {
            element: '1',
            left: {
                element: '3',
            },
            right: {
                element: '4',
            },
        },
        right: {
            element: '2',
            left: {
                element: '5',
                left: {
                    element: '7',
                    left: {
                        element: '10',
                    },
                },
            },
            right: {
                element: '6',
                left: {
                    element: '8',
                },
                right: {
                    element: '9',
                    left: {
                        element: '11',
                        left: {
                            element: '13',
                        },
                    },
                    right: {
                        element: '12',
                        right: {
                            element: '14',
                        },
                    },
                },
            },
        },
    };
};

let inorderCount = 0;
let preorderCount = 0;
let postorderCount = 0;


const inorderTraversal = async (node) => {
    const {left, right, element} = node;

    const domNode = document.getElementById(element);
    await sleep(500);

    domNode.style.backgroundColor = "lightgreen";

    left && await inorderTraversal(left);

    await sleep(500);
    domNode.style.backgroundColor = "pink";
    const cell = document.getElementById(`inorder-array-${inorderCount}`);
    inorderCount++;
    cell.innerHTML = element;

    right && await inorderTraversal(right);
}

const preorderTraversal = async (node) => {
    const {left, right, element} = node;

    const domNode = document.getElementById(element);
    await sleep(500);

    domNode.style.backgroundColor = "lightgreen";
    
    await sleep(500);
    domNode.style.backgroundColor = "pink";
    const cell = document.getElementById(`preorder-array-${preorderCount}`);
    preorderCount++;
    cell.innerHTML = element;

    left && await preorderTraversal(left);
    right && await preorderTraversal(right);
}

const postorderTraversal = async (node) => {
    const {left, right, element} = node;

    const domNode = document.getElementById(element);
    await sleep(500);

    domNode.style.backgroundColor = "lightgreen";

    left && await postorderTraversal(left);
    right && await postorderTraversal(right);

    await sleep(500);
    domNode.style.backgroundColor = "pink";
    const cell = document.getElementById(`postorder-array-${postorderCount}`);
    postorderCount++;
    cell.innerHTML = element
}

const resetBackgroundColors = () => {
    const elems = document.getElementsByClassName("node-element");
    const elements = Array.from(elems);

    elements.forEach((element) => {
        element.style.backgroundColor = "lightyellow";
    })
}

export const start = async (traversal, rootNode) => {
    resetBackgroundColors();

    if(traversal == "inorder"){
        await inorderTraversal(rootNode);
    }
    else if(traversal == "preorder"){
        await preorderTraversal(rootNode);
    }
    else{
        await postorderTraversal(rootNode);
    }
}
import React, { useState } from "react";
import './style.css';
import { generateFibNumbers } from "./algorithm";
import { BackNavigator } from "../BackNavigator";

export const FibonacciNumbers = () => {
    const [enabled, setEnabled] = useState(true);
    const device = navigator.userAgent.toLowerCase();

    console.log(device)

    const startFibSeries = () => {
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(device);
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(device);

        const num = (isMobile || isTablet) ? 14 : 15;

        setEnabled(false);
        generateFibNumbers(num);
    }

    return (
        <div className="fibonacci-numbers">
            <BackNavigator/>
            <div id="fibbars">
                <div id="bar0" class="bar"></div>
                <div id="bar1" class="bar"></div>
            </div>
            <div className="btnContainer">
                <button id="startSeriesBtn" className={enabled ? "tree-traversal-btn" : "tree-traversal-disabled-btn"} disabled={!enabled} onClick={startFibSeries}>Start</button>
                <button id="resetBtn" className={!enabled ? "tree-traversal-btn" : "tree-traversal-disabled-btn"} disabled={enabled} onClick={() => window.location.reload()}>Reset</button>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import './style.css';
import { getMazePlan } from "./script";
import { traverseBfs, traverseDfs } from "./algorithms";
import { Row } from "react-bootstrap";
import { BackNavigator } from "../BackNavigator";

export const VisualTraversal = () => {
    const mazePlan = getMazePlan();
    const [canTraverse, setCanTraverse] = useState(true);
    const startBfsTraversal = async () => {
        setCanTraverse(false);
        await traverseBfs(mazePlan);
    }
    const startDfsTraversal = async () => {
        setCanTraverse(false);
        await traverseDfs(mazePlan);
    }
    useEffect(() => {
        // document.getElementById("dfsBtn").addEventListener('click', () => traverseDfs(mazePlan));
        // document.getElementById("bfsBtn").addEventListener('click', () => traverseBfs(mazePlan));
        document.getElementById("resetBtn").addEventListener('click', () => window.location.reload());

    }, []);
    return (
        <div className="visual-traversal">
            <BackNavigator />
            <div className="visual-traversal-container">
                <div className="maze" id="maze">
                    {
                        mazePlan.map((row, i) => {
                            return row.map((val, j) => {
                                let backgroundColor = val ? "white" : "black";
                                if (i == 0 && j == 0) backgroundColor = "purple";
                                if (i == mazePlan.length - 1 && j == mazePlan[0].length - 1) backgroundColor = "green";
                                return (
                                    <div key={`cell-${i}-${j}`} style={{
                                        "gridColumnStart": j + 1,
                                        "gridColumnEnd": j + 2,
                                        "gridRowStart": i + 1,
                                        "gridRowEnd": i + 2,
                                        "border": "brown solid 1px",
                                        "backgroundColor": backgroundColor
                                    }}
                                        id={`cell-${i}-${j}`}>

                                    </div>
                                )
                            })
                        })
                    }
                </div>
                <Row className="buttons">
                    <button
                        className={`col-lg-3 col-sm-1 traversal-btns ${canTraverse ? "" : "disabled-graph-traversal-btn"}`}
                        id="dfsBtn"
                        onClick={() => startDfsTraversal()}
                        disabled={!canTraverse}
                    >
                        DFS Traversal
                    </button>
                    <button
                        className={`col-lg-3 col-sm-1 traversal-btns ${canTraverse ? "" : "disabled-graph-traversal-btn"}`}
                        id="bfsBtn"
                        onClick={() => startBfsTraversal()}
                        disabled={!canTraverse}
                    >
                        BFS Traversal
                    </button>
                    <button className="col-lg-3 col-sm-1 traversal-btns" id="resetBtn">Reset Values</button>
                </Row>
            </div>
        </div>
    )
}
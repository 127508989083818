export const arr = [3, 3, 4, 2, 4, 4, 2, 4, 4, 4, 2, 4, 4, 4, 4];


function sleep(time){
    return new Promise(resolve => {
        setTimeout(resolve, time)
    })
}

async function startMooresAlgorithm(arr) {
    let counter = 0;
    let counterElement = -1;

    for(let index=0; index<15; index++){
        let element = arr[index];

        await sleep(1000);
        const elementDiv = document.getElementById(`moore-algo-array-element-${index}`);
        elementDiv.style.backgroundColor = "lightblue";


        if(counter == 0){
            counterElement = element;
            const counterElementDiv = document.getElementById("moore-algo-counter-elem");
            counterElementDiv.innerHTML = element;
        }

        if(counterElement === element){
            counter++;
        }
        else{
            counter--;
        }

        await sleep(1000);
        const counterBar = document.getElementById("moore-algo-counter-bar");
        const counterBarHeight = (counter*30 + 1);
        counterBar.style.height = counterBarHeight + "px";

        console.log(counterBar.style.height);

        elementDiv.style.backgroundColor = "white";
    }
}

export {startMooresAlgorithm}

import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { Row, Col } from 'react-bootstrap';

export const Experience = () => {

    return (
        <section className="skill" id="experience">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>Experience</h2>
                            <h3>SWE-1 at OneAssist Consumer Solutions</h3>
                            <p>December 2021 - Present | Guragaon, India</p>
                            <Row>
                                <Col sm="12" md="6">
                                    <h4>Accomplishments</h4>
                                    <ul className="experience-list">
                                        <li>Built a Sales GPT Chat Application using ReactJS and Angular.</li>
                                        <li>Developed a versatile component library with Storybook for cross-platform utilization across OneAssist.</li>
                                        <li>Created a Shopify Application leveraging MongoDB, Express JS, React JS, Node.</li>
                                        <li>Developed a platform of visual charts for tracking sales and partner performance.</li>
                                        <li>Developed complete excess amount payment module in OneAssist Consumer Solutions App using React Native.</li>
                                        <li>Successfully migrated codebase from AngularJS to ReactJS.</li>
                                        <li>Reduced 4xx errors of API by using Kibana logs effectively.</li>
                                        <li>Developed and Managed Server Side Rendering of internal CRM application of the company.</li>
                                    </ul>
                                </Col>
                                <Col sm="12" md="6">
                                    <h4>Responsibilities</h4>
                                    <ul className="experience-list">
                                        <li>Creating common reusable components with HTML, CSS, JavaScript, and ReactJS.</li>
                                        <li>Implementing efficient algorithms to boost application performance for a seamless user experience.</li>
                                        <li>Overseeing task assignments, monitoring team progress, and providing resolution for queries.</li>
                                        <li>Developing codebase automation test cases with Cypress.</li>
                                        <li>Consistently updating codebase with latest libraries to optimize build times and enhance application efficiency.</li>
                                        <li>Conducting technical talks to enhance team knowledge across diverse topics.</li>
                                        <li>Engaging with Technical Leads and Managers to share insights on new technologies, updates, and strategies for optimizing application performance.</li>
                                        <li>Maintaining a documentation for all releases and features.</li>
                                        <li>Collaborating with team to swiftly develop/ enhance features.</li>
                                        <li>Collaborating with developers from various teams, addressing queries and meeting their requirements.</li>
                                        <li>Ensuring code cleanliness and maintaining it through rigorous code reviews.</li>
                                        <li>Regularly monitoring Kibana logs to minimize bugs.</li>
                                        <li>Collaborating with the support team to understand customer needs, addressing existing feature issues, and developing new features in response to queries</li>

                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp} alt="Image" />
        </section>
    )
}

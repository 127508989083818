import React, { useState } from "react";
import { BackNavigator } from "../BackNavigator";

import { getTreeData, start } from "./algorithm";
import { TreeNode } from "./TreeNode";
import './style.css';

export const TreeTraversal = () => {
    const [traversalUpdate, setTraversalUpdate] = useState({
        traversalActive: false,
        completedTraversals: [0, 0, 0]
    })
    const rootNode = getTreeData();
    const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    const getArrayCell = (traversalArray, index) => {
        return (
            <div
                key={index}
                id={`${traversalArray}-${index}`}
                style={{
                    gridColumnStart: index + 1,
                    gridColumnEnd: index + 2
                }}
            >

            </div>
        )
    }

    const startTraversal = async (traversal, rootNode) => {
        const newcompletedTraversals = [...traversalUpdate.completedTraversals];
        if (traversal === "inorder") {
            newcompletedTraversals[0] = 1;
        }
        else if (traversal === "preorder") {
            newcompletedTraversals[1] = 1;
        }
        else {
            newcompletedTraversals[2] = 1;
        }

        setTraversalUpdate({
            ...traversalUpdate,
            traversalActive: true
        })

        await start(traversal, rootNode);

        setTraversalUpdate({
            traversalActive: false,
            completedTraversals: [...newcompletedTraversals]
        })
    }

    return (
        <div className="tree-traversal-project-container">
            <BackNavigator />
            <h2>Tree Traversal</h2>
            <div id="tree" className="tree">
                <TreeNode key="root" node={rootNode} />
            </div>

            <div className="traversal-btn-array-container">
                <button
                    onClick={() => startTraversal("inorder", rootNode)}
                    disabled={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[0] ? true : false}
                    className={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[0] ? "disabled-traversal-btn" : "traversal-btn"}
                    id="inorder"

                >
                    Inorder
                </button>
                <div className="traversal-array" id="inorder-array">
                    {array.map(i => {
                        return getArrayCell("inorder-array", i);
                    })}
                </div>
            </div>

            <div className="traversal-btn-array-container">
                <button
                    onClick={() => startTraversal("preorder", rootNode)}
                    disabled={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[1] ? true : false}
                    className={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[1] ? "disabled-traversal-btn" : "traversal-btn"}
                    id="preorder">
                    Preorder
                </button>
                <div className="traversal-array" id="preorder-array">
                    {array.map(i => {
                        return getArrayCell("preorder-array", i);
                    })}
                </div>
            </div>

            <div className="traversal-btn-array-container">
                <button onClick={() => startTraversal("postorder", rootNode)}
                    disabled={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[2] ? true : false}
                    className={traversalUpdate.traversalActive || traversalUpdate.completedTraversals[2] ? "disabled-traversal-btn" : "traversal-btn"}
                    id="postorder">
                    Postorder
                </button>
                <div className="traversal-array" id="postorder-array">
                    {array.map(i => {
                        return getArrayCell("postorder-array", i);
                    })}
                </div>
            </div>
        </div>
    )
}
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import visualSortImg from '../assets/img/visual-sorting.png';
import visualTraversalImg from '../assets/img/visual-traversal.png';
import fibNumsImg from '../assets/img/fib-nums.png';
import treeTraversalImg from '../assets/img/tree-traversal.png';
import linkedListCycleImg from '../assets/img/linked-list-cycle.png';
import mooreVOtingAlgoImg from '../assets/img/moore-voting-algo.png';
import ishantClassImg from '../assets/img/ishant-commerce-classes.png';
import edigniteAppImg from '../assets/img/edignite-ngo-app.png';
import quotationClockImg from '../assets/img/quotation-clock.png';
import dailyQuotesImg from '../assets/img/daily-motivational-quotes.png';
import yourSmileImg from '../assets/img/your-smile.png';
import edigniteThemeImg from '../assets/img/edignite-color-theme.png';
import algorithmsKit from '../assets/img/algorithms-kit.png';
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const dsaProjectList = [
    {
      id: 1,
      title: "Visual Sorting",
      imgUrl: visualSortImg,
      link: "/visual-sorting-project"
    },
    {
      id: 2,
      title: "Visual Traversal Project",
      imgUrl: visualTraversalImg,
      link: "/visual-traversal-project"
    },
    {
      id: 3,
      title: "Fibonacci Numbers Project",
      imgUrl: fibNumsImg,
      link: "/fibonacci-numbers-project"
    },
    {
      id: 4,
      title: "Tree Traversal Project",
      imgUrl: treeTraversalImg,
      link: "/tree-traversal-project"
    },
    {
      id: 5,
      title: "Linked List Cycle",
      imgUrl: linkedListCycleImg,
      link: "/linked-list-cycle-project"
    },
    {
      id: 6,
      title: "Moore's Voting Algorithm",
      imgUrl: mooreVOtingAlgoImg,
      link: "/moore-voting-algo-project"
    },
  ];

  const developmentProjectList = [
    {
      id: 1,
      title: "Ishant Commerce Classes",
      imgUrl: ishantClassImg,
      link: "https://play.google.com/store/apps/details?id=com.ishantclasses.tutorapp&hl=en_SG"
    },
    {
      id: 7,
      title: "AlgorithmsKit npm package",
      imgUrl: algorithmsKit,
      link: "https://www.npmjs.com/package/algorithmskit"
    },
    {
      id: 2,
      title: "YourSmile App",
      imgUrl: yourSmileImg,
      link: "https://play.google.com/store/apps/details?id=com.yoursmile&hl=en_SG"
    },
    {
      id: 3,
      title: "Quotation Clock Extension",
      imgUrl: quotationClockImg,
      link: "https://chromewebstore.google.com/detail/quotation-clock/hmapoickfonbcpdccnkilbdbpaimgcgm"
    },
    {
      id: 4,
      title: "Daily Motivational Quotes Extension",
      imgUrl: dailyQuotesImg,
      link: "https://chromewebstore.google.com/detail/daily-motivational-quotes/mbajmhhhcicljddofhkfdgnnglofinie"
    },
    {
      id: 5,
      title: "Edignite Ngo Color Theme",
      imgUrl: edigniteThemeImg,
      link: "https://marketplace.visualstudio.com/items?itemName=PrinceVerma.edignitengo-theme"
    },
    {
      id: 6,
      title: "Edignite NGO App",
      imgUrl: edigniteAppImg,
      link: "https://play.google.com/store/apps/details?id=com.edignite.infoapp&hl=en_SG"
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Following are my projects showcasing the spectrum of my knowledge and skills.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="dsap">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="dsap" className="dsap-pill">DSA Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="developmentp" id="projects-tabs-tab-third">Development Projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="dsap">
                      <Row>
                        {
                          dsaProjectList.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="developmentp">
                      <Row>
                        {
                          developmentProjectList.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                isExternal
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
